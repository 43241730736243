<template>
  <v-app
  >
    <v-app-bar
      app
      color="white"
      light
      
    >
      <div class="d-flex align-center">
        <v-img
          alt="SELロゴ"
          class="shrink mr-2"
          contain
          src="./assets/sel-logo.webp"
          transition="scale-transition"
          width="80%"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://s-e-l.jp/"
        target="_blank"
        text
      >
        <span class="sm-none mr-2">安全環境研究所公式HP</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <TopImage/>
      <ContentsFirst/>
      <ContentsSecond/>
      <ContentsSecond02/>
      <LinenapAmisty/>
      <ContentsSecond03/>
      <ContentsForth/>
      <ContentsFive01/>
      <ContentsFive02/>
      <ContentsFive03/>
      <ContentsFive04/>
      <LinenapAmisty/>
      <ContentsSix/>
      <ContentsThird/>
      <LinenapAmisty/>
      <ReturnTopButton/>
      <MenuFooter/>
      <Footer/>
    </v-main>
  </v-app>
</template>

<script>
//import HelloWorld from './components/HelloWorld';
import TopImage from './components/TopImage';
import ContentsFirst from './components/ContentsFirst';
import ContentsSecond from './components/ContentsSecond';
import ContentsSecond02 from './components/ContentsSecond02';
import ContentsSecond03 from './components/ContentsSecond03';
import ContentsThird from './components/ContentsThird';
import ContentsForth from './components/ContentsForth';
import ContentsFive01 from './components/ContentsFive01';
import ContentsFive02 from './components/ContentsFive02';
import ContentsFive03 from './components/ContentsFive03';
import LinenapAmisty from './components/LinenapAmisty';
import ContentsFive04 from './components/ContentsFive04';
import ContentsSix from './components/ContentsSix';
import ReturnTopButton from './components/ReturnTopButton';
import Footer from './components/Footer.vue';
import MenuFooter from './components/MenuFooter.vue';

export default {
  name: 'App',

  components: {
    TopImage,
    ContentsFirst,
    ContentsSecond,
    ContentsSecond02,
    ContentsSecond03,
    ContentsThird,
    ContentsForth,
    ContentsFive01,
    ContentsFive02,
    ContentsFive03,
    LinenapAmisty,
    ContentsFive04,
    ContentsSix,
    ReturnTopButton,
    Footer,
    MenuFooter,
},

  data: () => ({
    //
  }),
};
</script>
<style lang="scss" scoped>
@media screen and (max-width:767px) {
  .sm-none{
    display: none;
  }
}
</style>
