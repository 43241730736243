import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
    primary: '#00bc5e',
    secondary: '#58E197',
    accent: '#E15858',
    error: '#FF5252',
          }
        }
    }
});
