<template>
<v-container class="contents-second__content-03">
  <v-row>
    <v-col cols="12">
      <h1>プロの現場で認められた除菌消臭水「アミスティー®️衛生水」</h1>
    <p>
        当社製品「アミスティー®️衛生水」は、安全性と衛生管理を求められる様々な現場で使用されています。
    </p>
    </v-col>
    <v-col 
    cols="12"
        sm="6"
        md="3"
        class="border-gr-box">
        <p class="img-box"><img src="../assets/icon-ami01-min.webp" alt=""></p>
      <p class="txt-box">ホテル・旅館<br>
        温浴施設</p>
    </v-col>
    <v-col
    cols="12"
        sm="6"
        md="3"
    class="border-gr-box">
        <p class="img-box"><img src="../assets/icon-ami02-min.webp" alt=""></p>
      <p class="txt-box">老人施設・介護施設<br>
      病院</p>
    </v-col>
    <v-col
    cols="12"
        sm="6"
        md="3"
    class="border-gr-box">
        <p class="img-box"><img src="../assets/icon-ami03-min.webp" alt=""></p>
      <p class="txt-box">保育園・幼稚園<br>学校</p>
    </v-col>
    <v-col
    cols="12"
        sm="6"
        md="3"
    class="border-gr-box">
        <p class="img-box"><img src="../assets/icon-ami04-min.webp" alt=""></p>
      <p class="txt-box">スポーツクラブ<br>
      ゴルフ場<br>
        リサイクル業 等</p>
    </v-col>
    <v-col>
        <v-carousel hide-delimiters
        cycle
    height="260">
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    ></v-carousel-item>
  </v-carousel>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('@/assets/sauna-contents-min.webp'),
          },
          {
            src: require('@/assets/yoga-contents-min.webp'),
          },
          {
            src: require('@/assets/golf-contents-min.webp'),
          },
          {
            src: require('@/assets/bas-contents-min.webp'),
          },
          {
            src: require('@/assets/hotel-contents-min.webp'),
          },
          {
            src: require('@/assets/toillet-contents-min.webp'),
          },
        ],
      }
    },
  }
</script>
<style lang="scss" scoped>
.contents-second__content-03{
    margin-bottom: 60px;
h1 {
  font-size: 1.5em;
    color: #ffffff;
  position: relative;
  padding: 0.6em;
  background: #E15858;
  margin: 40px 0;
}

h1:after {
  position: absolute;
  content: '';
  top: 100%;
  left: 30px;
  border: 15px solid transparent;
  border-top: 15px solid #E15858;
  width: 0;
  height: 0;
  }
img{
    width: 100%;
    height: auto;
    margin-bottom: 40px;
}
p{
  line-height: 230%;
        .bc-yl{
            background: #F2FF59;
            padding: 8px 0px 4px 0px;
            font-weight: bold;
        }
    }
    h3{
        border-left: #00BC5E solid 5px;
        color: #00BC5E;
        padding: 10px;
        margin: 20px 0;
    }
        .border-gr-box{
            margin: 40px auto 60px auto;
            position: relative;
            padding: 10px;
            text-align: center;
            font-weight: bold;
            .img-box{
                position: absolute;
                margin-top: -60px;
                width: 40%;
                left: 30%;
            }
            .txt-box{
                padding: 26% 10px 20px 10px;
                border: 1px solid #00BC5E;
            }
        }
}
@media screen and (max-width:767px) {
.contents-second__content-03{
    margin: 0px 0 60px 0;
h1 {
  font-size: 1.25rem;
}
  }
}
</style>