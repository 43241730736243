<template>
  <v-carousel class="home-hero__content"
  height= "70vh"
  cycle
    hide-delimiter-background
    show-arrows-on-hover>
    <v-carousel-item
      v-for="(item,i) in items"
      :key="i"
      :src="item.src"
    >
    <v-row
          class="home-hero__content-img"
          align="center"
          justify="center"
        >
          <div>
            <img
          alt="トップテキスト"
          src="../assets/amisty-lp-img.webp"
        />
          </div>
        </v-row>
    </v-carousel-item>
  </v-carousel>
</template>
<script>
  export default {
    data () {
      return {
        items: [
          {
            src: require('@/assets/sauna-top01-min.webp'),
          },
          {
            src: require('@/assets/yoga-top02-min.webp'),
          },
          {
            src: require('@/assets/golf-top03-min.webp'),
          },
        ],
      }
    },
  }
</script>

<style  lang="scss" scoped>
.home-hero__content {
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 70vh;
  .home-hero__content-img{
    width: 100%;
    height: 60vh;
    text-align: center;
    img{
    height: 100%;
    }
  }
}
@media screen and (max-width:767px) {
  .home-hero__content {
    height: 100vh;
  .home-hero__content-img{
    width: 100%;
    text-align: center;
    margin: 0 auto;
    img{
       width: 90%;
    height: auto;
    }
  }
}
}
</style>