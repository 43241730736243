<template>
    
       <!--
        <div class="text-center mb-8">
        <v-btn
        color="primary"
        @click="toggle = !toggle"
      >
        ボトムナビゲーションのON/OFF
      </v-btn>
      </div>-->
    <v-bottom-navigation color="primary" v-model="isActive" height="76px" v-bind:input-value="toggle" fixed="true" class="menufooter">
      
      <v-btn value="menu01"
       href="https://s-e-l.jp/sel-company/amisty-contact/" target="_blank" rel="noopener noreferrer">
        <span>消臭力体験はこちら</span>
        <v-icon>mdi-star-four-points</v-icon>
      </v-btn>
        <v-btn value="menu02"
        href="https://official-shop.s-e-l.jp/?mode=cate&cbid=2737686&csid=0" target="_blank" rel="noopener noreferrer">
        <span>公式ショップ</span>
        <v-icon>mdi-store</v-icon>
      </v-btn>
      
    </v-bottom-navigation>
</template>
<script>
 export default {
    data: () => ({
        isActive: 'menu01',
        toggle: true
      }
   )}
</script>
<style lang="scss" scoped>
.menufooter{
    display: none;
    height: 80px;
  }
@media screen and (max-width:767px) {
  .menufooter{
    display: block;
  }
}
</style>